<template>
  <div class="home">
    <div id="page-hero" class="pt-6 pb-2 py-md-12 white--text container--fluid">
      <div class="container">
        <v-row>
          <v-col cols="12" sm="12">
            <h1 class="display-2 mb-4">{{ tenant.name }}</h1>
            <p>
              {{ tenant.description }}
            </p>
            <div>
              <v-btn class="mr-4 mb-4" color="white" :to="{name:'activityEntry'}">{{ $t('nav.account.manual-entry') }}</v-btn>
              
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-container class="mb-12">
      <v-row>
        <v-col>
          <v-card-title class="subtitle">{{$t('events.races-and-leaderboards')}}</v-card-title>

          <div v-if="event && $helpers.isFutureEvent(event)" class="mx-4">
            <CountDownTimer :label="$t('events.rules.starts-in')" :countDownDate="event.from" />
          </div>
          
          <RaceGrid v-if="event" :items="event.races.filter(x => x.visibility !== 'PRIVATE' && x.visibility !== 'MUTED')" :event="event" class="px-4"></RaceGrid>
          
        </v-col>
      </v-row>
    </v-container>

    <div class="my-12">&nbsp;</div>
  </div>
  
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import eventService from "@/services/eventService";
import CountDownTimer from "@/components/generic/CountDownTimer.vue";
import RaceGrid from "@/components/RaceGrid.vue";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "home",
  components: {
    CountDownTimer,
    RaceGrid,
  },
  data() {
    return {
      tenant: tenant,
      event: null,
    };
  },
  async mounted() {
    this.event = (await eventService.get(tenant.primaryEventId)).data;

  }, 
};
</script>

<style lang="scss" scoped>
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style> 